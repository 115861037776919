define("ember-cli-mirage/deprecate-reexports", ["exports", "miragejs", "ember-cli-mirage/index", "@embroider/macros/runtime"], function (_exports, mirage, ecMirageExports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initDeprecatedReExports = initDeprecatedReExports;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var nonDeprecatedImports = ['default'];

  /**
   @function initDeprecatedReExports
   @hide
   */
  function initDeprecatedReExports() {
    Object.entries(mirage).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        name = _ref2[0],
        value = _ref2[1];
      if (!nonDeprecatedImports.includes(name)) {
        // eslint-disable-next-line no-import-assign
        Object.defineProperty(ecMirageExports, name, {
          get: function get() {
            if ((0, _runtime.isTesting)() && true) {
              var _importSync = require("@ember/test-helpers"),
                waitUntil = _importSync.waitUntil,
                getContext = _importSync.getContext;
              window.QUnit.begin(function () {
                // Make sure deprecation message does not get "swallowed"
                // when tests run due to
                // https://github.com/emberjs/ember-test-helpers/blob/master/addon-test-support/%40ember/test-helpers/setup-context.ts#L41
                waitUntil(function () {
                  return getContext() !== undefined;
                }).then(function () {
                  return _deprecate(name);
                });
              });
            } else {
              _deprecate(name);
            }
            return value;
          },
          enumerable: true
        });
      }
    });
  }
  function _deprecate(name) {
    var message = "Importing '".concat(name, "' from 'ember-cli-mirage' is deprecated.") + " Install the `miragejs` package and use " + "`import { ".concat(name, " } from 'miragejs';` instead.");
    (true && !(false) && Ember.deprecate(message, false, {
      id: 'ember-cli-mirage.miragejs.import',
      until: '3.0.0',
      for: 'ember-cli-mirage',
      since: {
        enabled: '2.3.0'
      }
    }));
  }
});