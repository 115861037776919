define("ember-bootstrap/components/bs-accordion/item/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsCollapse @collapsed={{@collapsed}} class={{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-collapse"}} role="tabpanel">
    <div class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "card-body"}} {{if (macroCondition (macroGetOwnConfig "isBS3")) "panel-body"}} {{@class}}">
      {{yield}}
    </div>
  </BsCollapse>
  */
  {
    "id": "22d9IJav",
    "block": "{\"symbols\":[\"@collapsed\",\"@class\",\"&default\"],\"statements\":[[8,\"bs-collapse\",[[16,0,[29]],[24,\"role\",\"tabpanel\"]],[[\"@collapsed\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"card-body\",\" \",[29],\" \",[32,2]]]],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-accordion/item/body.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});