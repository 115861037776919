define("@ember-data/private-build-infra/deprecations", ["exports", "@ember-data/private-build-infra/current-deprecations"], function (_exports, _currentDeprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEPRECATE_SERIALIZER_QUERY_RECORD_ARRAY_RESPONSE = _exports.DEPRECATE_REFERENCE_INTERNAL_MODEL = _exports.DEPRECATE_RECORD_LIFECYCLE_EVENT_METHODS = _exports.DEPRECATE_MODEL_TOJSON = _exports.DEPRECATE_MODEL_DATA = _exports.DEPRECATE_MISMATCHED_INVERSE_RELATIONSHIP_DATA = _exports.DEPRECATE_METHOD_CALLS_ON_DESTROY_STORE = _exports.DEPRECATE_LEGACY_TEST_REGISTRATIONS = _exports.DEPRECATE_LEGACY_TEST_HELPER_SUPPORT = _exports.DEPRECATE_EVENTED_API_USAGE = _exports.DEPRECATE_DEFAULT_SERIALIZER = _exports.DEPRECATE_DEFAULT_ADAPTER = _exports.DEPRECATE_CATCH_ALL = _exports.DEPRECATE_BELONGS_TO_REFERENCE_PUSH = void 0;
  function deprecationState(deprecationName) {
    // if we hit this at runtime and the deprecation exists it is always activated
    return deprecationName in _currentDeprecations.default;
  }

  // deprecations
  var DEPRECATE_CATCH_ALL = deprecationState('DEPRECATE_CATCH_ALL');
  _exports.DEPRECATE_CATCH_ALL = DEPRECATE_CATCH_ALL;
  var DEPRECATE_EVENTED_API_USAGE = deprecationState('DEPRECATE_EVENTED_API_USAGE');
  _exports.DEPRECATE_EVENTED_API_USAGE = DEPRECATE_EVENTED_API_USAGE;
  var DEPRECATE_RECORD_LIFECYCLE_EVENT_METHODS = deprecationState('DEPRECATE_RECORD_LIFECYCLE_EVENT_METHODS');
  _exports.DEPRECATE_RECORD_LIFECYCLE_EVENT_METHODS = DEPRECATE_RECORD_LIFECYCLE_EVENT_METHODS;
  var DEPRECATE_MODEL_DATA = deprecationState('DEPRECATE_MODEL_DATA');
  _exports.DEPRECATE_MODEL_DATA = DEPRECATE_MODEL_DATA;
  var DEPRECATE_MODEL_TOJSON = deprecationState('DEPRECATE_MODEL_TOJSON');
  _exports.DEPRECATE_MODEL_TOJSON = DEPRECATE_MODEL_TOJSON;
  var DEPRECATE_LEGACY_TEST_HELPER_SUPPORT = deprecationState('DEPRECATE_LEGACY_TEST_HELPER_SUPPORT');
  _exports.DEPRECATE_LEGACY_TEST_HELPER_SUPPORT = DEPRECATE_LEGACY_TEST_HELPER_SUPPORT;
  var DEPRECATE_LEGACY_TEST_REGISTRATIONS = deprecationState('DEPRECATE_LEGACY_TEST_REGISTRATIONS');
  _exports.DEPRECATE_LEGACY_TEST_REGISTRATIONS = DEPRECATE_LEGACY_TEST_REGISTRATIONS;
  var DEPRECATE_DEFAULT_SERIALIZER = deprecationState('DEPRECATE_DEFAULT_SERIALIZER');
  _exports.DEPRECATE_DEFAULT_SERIALIZER = DEPRECATE_DEFAULT_SERIALIZER;
  var DEPRECATE_DEFAULT_ADAPTER = deprecationState('DEPRECATE_DEFAULT_ADAPTER');
  _exports.DEPRECATE_DEFAULT_ADAPTER = DEPRECATE_DEFAULT_ADAPTER;
  var DEPRECATE_METHOD_CALLS_ON_DESTROY_STORE = deprecationState('DEPRECATE_METHOD_CALLS_ON_DESTROY_STORE');
  _exports.DEPRECATE_METHOD_CALLS_ON_DESTROY_STORE = DEPRECATE_METHOD_CALLS_ON_DESTROY_STORE;
  var DEPRECATE_SERIALIZER_QUERY_RECORD_ARRAY_RESPONSE = deprecationState('DEPRECATE_SERIALIZER_QUERY_RECORD_ARRAY_RESPONSE');
  _exports.DEPRECATE_SERIALIZER_QUERY_RECORD_ARRAY_RESPONSE = DEPRECATE_SERIALIZER_QUERY_RECORD_ARRAY_RESPONSE;
  var DEPRECATE_MISMATCHED_INVERSE_RELATIONSHIP_DATA = deprecationState('DEPRECATE_MISMATCHED_INVERSE_RELATIONSHIP_DATA');
  _exports.DEPRECATE_MISMATCHED_INVERSE_RELATIONSHIP_DATA = DEPRECATE_MISMATCHED_INVERSE_RELATIONSHIP_DATA;
  var DEPRECATE_BELONGS_TO_REFERENCE_PUSH = deprecationState('DEPRECATE_BELONGS_TO_REFERENCE_PUSH');
  _exports.DEPRECATE_BELONGS_TO_REFERENCE_PUSH = DEPRECATE_BELONGS_TO_REFERENCE_PUSH;
  var DEPRECATE_REFERENCE_INTERNAL_MODEL = deprecationState('DEPRECATE_REFERENCE_INTERNAL_MODEL');
  _exports.DEPRECATE_REFERENCE_INTERNAL_MODEL = DEPRECATE_REFERENCE_INTERNAL_MODEL;
});