define("ember-component-css/initializers/component-styles", ["exports", "ember-component-css/pod-names"], function (_exports, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var ComponentLookup = Ember.ComponentLookup;
  ComponentLookup.reopen({
    componentFor: function componentFor(name, owner) {
      owner = owner.hasRegistration ? owner : Ember.getOwner(this);
      if (_podNames.default[name] && !owner.hasRegistration("component:".concat(name))) {
        owner.register("component:".concat(name), Ember.Component);
      }
      return this._super.apply(this, arguments);
    }
  });
  Ember.Component.reopen({
    _componentIdentifier: Ember.computed({
      get: function get() {
        return (this._debugContainerKey || '').replace('component:', '');
      }
    }),
    _shouldAddNamespacedClassName: Ember.computed({
      get: function get() {
        return this.get('tagName') !== '' && this.get('styleNamespace');
      }
    }),
    styleNamespace: Ember.computed({
      get: function get() {
        return _podNames.default[this.get('_componentIdentifier')] || '';
      }
    }),
    // componentCssClassName: deprecatingAlias('styleNamespace', {
    //   id: 'ember-component-css.deprecate-componentCssClassName',
    //   until: '0.7.0',
    // }),

    componentCssClassName: Ember.computed.alias('styleNamespace'),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('_shouldAddNamespacedClassName')) {
        this.classNames = this.classNames.concat(this.get('styleNamespace'));
      }
    }
  });
  function initialize() {}
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});