define("ember-bootstrap/components/bs-modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let
    (ensure-safe-component (bs-default @titleComponent (component "bs-modal/header/title")))
    (component (ensure-safe-component (bs-default @closeComponent (component "bs-modal/header/close"))) onClick=@onClose)
  as |Title Close|
  }}
    <div class="modal-header" ...attributes>
      {{#if (has-block-params)}}
        {{yield
          (hash
            title=Title
            close=Close
          )
        }}
      {{else}}
        {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            <Title>{{@title}}</Title>
          {{/if}}
          {{#if (bs-default @closeButton true)}}
            <Close/>
          {{/if}}
        {{/if}}
        {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
          {{#if (bs-default @closeButton true)}}
            <Close/>
          {{/if}}
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            <Title>{{@title}}</Title>
          {{/if}}
        {{/if}}
      {{/if}}
    </div>
  {{/let}}
  */
  {
    "id": "Rgx5xCL2",
    "block": "{\"symbols\":[\"Title\",\"Close\",\"@title\",\"&default\",\"@namedBlocksInfo\",\"@closeButton\",\"&attrs\",\"@onClose\",\"@closeComponent\",\"@titleComponent\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,2],[[32,10],[30,[36,5],[\"bs-modal/header/title\"],null]],null]],null],[30,[36,5],[[30,[36,6],[[30,[36,2],[[32,9],[30,[36,5],[\"bs-modal/header/close\"],null]],null]],null]],[[\"onClick\"],[[32,8]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"modal-header\"],[17,7],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[32,5],\"default\",[28,[32,4]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,4,[[30,[36,3],null,[[\"title\",\"close\"],[[32,1],[32,2]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,5],\"default\",[27,[32,4]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,3]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,2],[[32,6],true],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,[32,2],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"-has-block\",\"if\",\"bs-default\",\"hash\",\"-has-block-params\",\"component\",\"ensure-safe-component\",\"let\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/header.hbs"
    }
  });
  /**
  
   Modal header element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalHeader
   @namespace Components
   @extends Glimmer.Component
   @public
   */

  /**
   * Show a close button (x icon)
   *
   * @property closeButton
   * @type boolean
   * @default true
   * @public
   */

  /**
   * The title to display in the modal header
   *
   * @property title
   * @type string
   * @default null
   * @public
   */

  /**
   * @property titleComponent
   * @type {String}
   * @private
   */

  /**
   * @property closeComponent
   * @type {String}
   * @private
   */

  /**
   * @event onClose
   * @public
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});