define("@ember/test-waiters/waiter-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._reset = _reset;
  _exports.getPendingWaiterState = getPendingWaiterState;
  _exports.getWaiters = getWaiters;
  _exports.hasPendingWaiters = hasPendingWaiters;
  _exports.register = register;
  _exports.unregister = unregister;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  // this ensures that if @ember/test-waiters exists in multiple places in the
  // build output we will still use a single map of waiters (there really should
  // only be one of them, or else `settled` will not work at all)
  var WAITERS = function () {
    var HAS_SYMBOL = typeof Symbol !== 'undefined';
    var symbolName = 'TEST_WAITERS';
    var symbol = HAS_SYMBOL ? Symbol.for(symbolName) : symbolName;
    var global = getGlobal();
    var waiters = global[symbol];
    if (waiters === undefined) {
      waiters = global[symbol] = new Map();
    }
    return waiters;
  }();
  function indexable(input) {
    return input;
  }
  function getGlobal() {
    // eslint-disable-next-line node/no-unsupported-features/es-builtins
    if (typeof globalThis !== 'undefined') return indexable(globalThis);
    if (typeof self !== 'undefined') return indexable(self);
    if (typeof window !== 'undefined') return indexable(window);
    if (typeof global !== 'undefined') return indexable(global);
    throw new Error('unable to locate global object');
  }
  /**
   * Backwards compatibility with legacy waiters system.
   *
   * We want to always register a waiter using the legacy waiter system, as right
   * now if consumers are not on the right version of @ember/test-helpers, using
   * this addon will result in none of these waiters waiting.
   */
  // eslint-disable-next-line ember/new-module-imports

  if (Ember.Test) {
    Ember.Test.registerWaiter(function () {
      return !hasPendingWaiters();
    });
  }
  /**
   * Registers a waiter.
   *
   * @public
   * @param waiter {Waiter} A test waiter instance
   */

  function register(waiter) {
    WAITERS.set(waiter.name, waiter);
  }
  /**
   * Un-registers a waiter.
   *
   * @public
   * @param waiter {Waiter} A test waiter instance
   */

  function unregister(waiter) {
    WAITERS.delete(waiter.name);
  }
  /**
   * Gets an array of all waiters current registered.
   *
   * @public
   * @returns {Waiter[]}
   */

  function getWaiters() {
    var result = [];
    WAITERS.forEach(function (value) {
      result.push(value);
    });
    return result;
  }
  /**
   * Clears all waiters.
   *
   * @private
   */

  function _reset() {
    var _iterator = _createForOfIteratorHelper(getWaiters()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var waiter = _step.value;
        waiter.isRegistered = false;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    WAITERS.clear();
  }
  /**
   * Gets the current state of all waiters. Any waiters whose
   * `waitUntil` method returns false will be considered `pending`.
   *
   * @returns {PendingWaiterState} An object containing a count of all waiters
   * pending and a `waiters` object containing the name of all pending waiters
   * and their debug info.
   */

  function getPendingWaiterState() {
    var result = {
      pending: 0,
      waiters: {}
    };
    WAITERS.forEach(function (waiter) {
      if (!waiter.waitUntil()) {
        result.pending++;
        var debugInfo = waiter.debugInfo();
        result.waiters[waiter.name] = debugInfo || true;
      }
    });
    return result;
  }
  /**
   * Determines if there are any pending waiters.
   *
   * @returns {boolean} `true` if there are pending waiters, otherwise `false`.
   */

  function hasPendingWaiters() {
    var state = getPendingWaiterState();
    return state.pending > 0;
  }
});