function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
(function () {
  'use strict';

  if (true) {
    // @ts-ignore
    var glimmerRuntime = Ember.__loader.require('@glimmer/runtime');

    // Ember.destroy is already set by default, ignoring it here

    Ember._registerDestructor = glimmerRuntime.registerDestructor;
    Ember._unregisterDestructor = glimmerRuntime.unregisterDestructor;
    Ember._associateDestroyableChild = glimmerRuntime.associateDestroyableChild;
    Ember._isDestroying = glimmerRuntime.isDestroying;
    Ember._isDestroyed = glimmerRuntime.isDestroyed;

    // on 3.20.0-beta.4 through 3.20.2 (estimated) there is an issue with the upstream
    // `assertDestroyablesDestroyed` method that triggers the assertion in cases that it
    // should not; in order to allow code bases to function on those specific Ember versions
    // (including our own test suite) we detect and do nothing
    //
    // See https://github.com/glimmerjs/glimmer-vm/pull/1119
    if (true) {
      Ember._assertDestroyablesDestroyed = glimmerRuntime.assertDestroyablesDestroyed;
      Ember._enableDestroyableTracking = glimmerRuntime.enableDestroyableTracking;
    } else {
      Ember._assertDestroyablesDestroyed = function () {};
      Ember._enableDestroyableTracking = function () {};
    }
  } else {
    var getDestructors = function getDestructors(destroyable) {
      if (!DESTRUCTORS.has(destroyable)) {
        DESTRUCTORS.set(destroyable, new Set());
      }
      return DESTRUCTORS.get(destroyable);
    };
    var getDestroyableChildren = function getDestroyableChildren(destroyable) {
      if (!DESTROYABLE_CHILDREN.has(destroyable)) {
        DESTROYABLE_CHILDREN.set(destroyable, new Set());
      }
      return DESTROYABLE_CHILDREN.get(destroyable);
    };
    var isDestroying = function isDestroying(destroyable) {
      return Ember.meta(destroyable).isSourceDestroying();
    };
    var isDestroyed = function isDestroyed(destroyable) {
      return Ember.meta(destroyable).isSourceDestroyed();
    };
    var assertNotDestroyed = function assertNotDestroyed(destroyable) {
      (true && !(!isDestroyed(destroyable)) && Ember.assert("'".concat(destroyable, "' was already destroyed."), !isDestroyed(destroyable)));
      (true && !(!isDestroying(destroyable)) && Ember.assert("'".concat(destroyable, "' is already being destroyed."), !isDestroying(destroyable)));
    };
    var associateDestroyableChild = function associateDestroyableChild(parent, child) {
      if (true /* DEBUG */) assertNotDestroyed(parent);
      if (true /* DEBUG */) assertNotDestroyed(child);
      (true && !(!DESTROYABLE_PARENTS.has(child)) && Ember.assert("'".concat(child, "' is already a child of '").concat(parent, "'."), !DESTROYABLE_PARENTS.has(child)));
      DESTROYABLE_PARENTS.set(child, parent);
      getDestroyableChildren(parent).add(child);
      return child;
    };
    var unregisterDestructor = function unregisterDestructor(destroyable, destructor) {
      if (true /* DEBUG */) assertNotDestroyed(destroyable);
      var destructors = getDestructors(destroyable);
      (true && !(destructors.has(destructor)) && Ember.assert("'".concat(destructor, "' is not registered with '").concat(destroyable, "'."), destructors.has(destructor)));
      destructors.delete(destructor);
    };
    var registerDestructor = function registerDestructor(destroyable, destructor) {
      if (true /* DEBUG */) assertNotDestroyed(destroyable);
      var destructors = getDestructors(destroyable);
      (true && !(!destructors.has(destructor)) && Ember.assert("'".concat(destructor, "' is already registered with '").concat(destroyable, "'."), !destructors.has(destructor)));
      destructors.add(destructor);
      return destructor;
    };
    var destroy = function destroy(destroyable) {
      if (isDestroying(destroyable) || isDestroyed(destroyable)) return;
      if (true) {
        // Ember.destroy calls setSourceDestroying (which runs runDestructors) and schedules setSourceDestroyed
        _upstreamDestroy(destroyable);
        return;
      }
      var m = Ember.meta(destroyable);
      m.setSourceDestroying(); // This calls `runDestructors`
    };
    var runDestructors = function runDestructors(destroyable) {
      if (RUNNING.has(destroyable)) return;
      RUNNING.add(destroyable);
      var m = Ember.meta(destroyable);
      var _iterator = _createForOfIteratorHelper(getDestroyableChildren(destroyable)),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var child = _step.value;
          destroy(child);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      var _iterator2 = _createForOfIteratorHelper(getDestructors(destroyable)),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var destructor = _step2.value;
          Ember.run.schedule('actions', undefined, destructor, destroyable);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      Ember.run.schedule('destroy', function () {
        if (!true) {
          // between Ember 2.18 and 3.16.4 Ember.destroy
          _upstreamDestroy(destroyable);
          m.setSourceDestroyed();
        }
        DESTRUCTORS.delete(destroyable);
        DESTROYABLE_PARENTS.delete(destroyable);
      });
    };
    var enableDestroyableTracking = function enableDestroyableTracking() {
      DESTRUCTORS = new Map();
      DESTROYABLE_PARENTS = new Map();
      isTesting = true;
    };
    var assertDestroyablesDestroyed = function assertDestroyablesDestroyed() {
      if (!isTesting) {
        throw new Error('Attempted to assert destroyables destroyed, but you did not start a destroyable test. Did you forget to call `enableDestroyableTracking()`');
      }
      var destructors = DESTRUCTORS;
      var children = DESTROYABLE_PARENTS;
      isTesting = false;
      DESTRUCTORS = new WeakMap();
      DESTROYABLE_PARENTS = new WeakMap();
      if (destructors.size > 0 || children.size > 0) {
        var error = new Error("Some destroyables were not destroyed during this test");
        Object.defineProperty(error, 'destroyables', {
          get: function get() {
            return _toConsumableArray(new Set([].concat(_toConsumableArray(destructors.keys()), _toConsumableArray(children.keys()))));
          }
        });
        throw error;
      }
    };
    var Meta = true ? Ember.__loader.require('@ember/-internals/meta/lib/meta').Meta : Ember.__loader.require('ember-meta/lib/meta').Meta;
    var isTesting = false;
    var DESTRUCTORS = new WeakMap();
    var DESTROYABLE_PARENTS = new WeakMap();
    var DESTROYABLE_CHILDREN = new WeakMap();

    /**
     * Tears down the meta on an object so that it can be garbage collected.
     * Multiple calls will have no effect.
     *
     * On Ember < 3.16.4 this just calls `meta.destroy`
     * On Ember >= 3.16.4 this calls setSourceDestroying and schedules setSourceDestroyed + `meta.destroy`
     *
     * @param {Object} obj  the object to destroy
     * @return {void}
     */
    var _upstreamDestroy = Ember.destroy;
    var RUNNING = new WeakSet();
    var setSourceDestroying = Meta.prototype.setSourceDestroying;
    Meta.prototype.setSourceDestroying = function () {
      setSourceDestroying.call(this);
      runDestructors(this.source);
    };
    var callWillDestroy = function callWillDestroy(instance) {
      return instance.willDestroy();
    };

    // would prefer a WeakSet here but not available on IE11
    var willDestroyRegistered = new WeakMap();
    Ember.CoreObject.prototype.init = function destroyablesPolyfill_init() {
      if (!willDestroyRegistered.has(this)) {
        registerDestructor(this, callWillDestroy);
        willDestroyRegistered.set(this, true);
      }
    };
    Ember.CoreObject.prototype.destroy = function destroyablesPolyfill_destroy() {
      destroy(this);
      return this;
    };
    Ember.destroy = destroy;
    Ember._registerDestructor = registerDestructor;
    Ember._unregisterDestructor = unregisterDestructor;
    Ember._associateDestroyableChild = associateDestroyableChild;
    Ember._isDestroying = isDestroying;
    Ember._isDestroyed = isDestroyed;
    Ember._assertDestroyablesDestroyed = assertDestroyablesDestroyed;
    Ember._enableDestroyableTracking = enableDestroyableTracking;
  }
})();