define("ember-bootstrap/components/bs-modal/header/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
    <h5 class="modal-title" ...attributes>
      {{yield}}
    </h5>
  {{/if}}
  {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    <h4 class="modal-title" ...attributes>
      {{yield}}
    </h4>
  {{/if}}
  */
  {
    "id": "Uc9v2VA2",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[2,\"  \"],[11,\"h5\"],[24,0,\"modal-title\"],[17,1],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-bootstrap/components/bs-modal/header/title.hbs"
    }
  });
  /**
  
   @class ModalHeaderTitle
   @namespace Components
   @extends Glimmer.Component
   @private
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});