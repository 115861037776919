define("ember-named-blocks-polyfill/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBlocksInfo = isBlocksInfo;
  _exports.isNamedBlockInvocation = isNamedBlockInvocation;
  _exports.namedBlockInvocation = namedBlockInvocation;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  /**
   * @type {WeakMap<object, string>}
   */
  var INVOCATIONS = new WeakMap();

  /**
   * Constructs a named block invocation for the block named _block_.
   *
   * @param {string} block
   * @returns {unknown}
   */
  function namedBlockInvocation(block) {
    var invocation = Object.create(null);
    Object.defineProperty(invocation, 'toString', {
      configurable: true,
      enumerable: false,
      writable: false,
      value: function value() {
        return "{{yield to=".concat(JSON.stringify(block), "}}");
      }
    });
    INVOCATIONS.set(invocation, block);
    return invocation;
  }

  /**
   * Check if _value_ is a named block invocation for the block named _block_.
   *
   * @param {unknown} value
   * @param {string} block
   * @returns {boolean}
   */
  function isNamedBlockInvocation(value, block) {
    if (_typeof(value) === 'object' && value !== null && INVOCATIONS.has(value)) {
      return block === INVOCATIONS.get(value);
    } else {
      return block === 'default';
    }
  }

  /**
   * @typedef {{ [key: string]: number }} BlocksInfo
   *
   * @param {unknown} value
   * @returns {value is BlocksInfo}
   */
  function isBlocksInfo(value) {
    return _typeof(value) === 'object' && value !== null && Object.getPrototypeOf(value) === null && Object.values(value).every(function (v) {
      return typeof v === 'number';
    });
  }
});